import {
  isRegistrationClosedVisible,
  getRestrictedTo,
  isRegistrationClosed,
  getAboutText,
  hasGuestsGoing as commonsHasGuestsGoing,
  isRsvp,
  isLocationTbd,
  getEventDescription,
  isTicketed,
  isEventLocationOnline,
  isWithoutRegistration,
  isRegistrationScheduled,
  hasSeatingPlan,
} from '@wix/wix-events-commons-statics'
import {VisitorType} from '@wix/events-types'
import {isEditor} from '../../../../commons/selectors/environment'
import {State, ThankYouMessageState} from '../types'
import {isMemberRsvpExists} from './member-rsvp'
import {areSelectedDonationsValid, getSelectedTicketsQuantity} from './selected-tickets'
import {
  isAboutSectionVisibilityEnabled,
  isDescriptionEnabled,
  isMapEnabled,
  isPageRegistrationButtonVisibilityEnabled,
} from './settings'
import {hasTicketsOnSale} from './tickets'

const DEFAULT_GUEST_COUNT = 3
export const getDefaultGuestTotal = (isRsvpEvent: boolean): TotalEventGuests =>
  isRsvpEvent ? {YES: DEFAULT_GUEST_COUNT} : DEFAULT_GUEST_COUNT

export const getFormMessages = (event: wix.events.Event, state: ThankYouMessageState) => {
  const {negativeMessages, waitlistMessages, positiveMessages} = event.form.messages.rsvp
  switch (state) {
    case 'no':
      return negativeMessages
    case 'waiting':
      return waitlistMessages
    default:
      return positiveMessages
  }
}

export const hasGuestsGoing = (state: State) => {
  const totalGuests = state.event?.totalGuests ?? 0
  const guestsGoing = commonsHasGuestsGoing(totalGuests, isRsvp(state.event))

  if (typeof totalGuests === 'object') {
    return Object.keys(totalGuests)?.length > 0 && guestsGoing
  }

  return Boolean(totalGuests) && guestsGoing
}

export const getTotalGuests = (state: State): TotalEventGuests => {
  const isRsvpEvent = isRsvp(state.event)
  const areGuestsGoing = hasGuestsGoing(state)
  if (!areGuestsGoing && isEditor(state)) {
    return getDefaultGuestTotal(isRsvpEvent)
  }
  return state.event.totalGuests
}

export const getMembers = (state: State): Member[] => state.event.members || []

export const isAboutSectionVisible = (state: State) =>
  isAboutSectionVisibilityEnabled(state.component.settings) && (isEditor(state) || !!getAboutText(state.event))

export const isMapVisible = (state: State) =>
  isMapEnabled(state.component.settings) && !isLocationTbd(state.event) && !isEventLocationOnline(state.event)

export const isSecondRegistrationButtonVisible = (state: State) => {
  const event = state.event
  const rsvpButtonVisible = isPageRegistrationButtonVisibilityEnabled(state.component.settings)
  const registrationClosed = isRegistrationClosed(event)
  const ticketedEvent = isTicketed(event)
  const withoutRegistration = isWithoutRegistration(event)
  const memberRsvpExists = isMemberRsvpExists(state)
  const registrationScheduled = isRegistrationScheduled(event)

  if (
    !rsvpButtonVisible ||
    registrationClosed ||
    memberRsvpExists ||
    ticketedEvent ||
    withoutRegistration ||
    registrationScheduled
  ) {
    return false
  }

  return true
}

export const isDemoEvent = (state: State) => Boolean(Object.keys(state.demoEvents).length)

export const isDescriptionVisible = (state: State) =>
  isDescriptionEnabled(state.component.settings) && !!getEventDescription(state.event)

export const isCheckoutButtonDisabled = (state: State) => {
  if (hasSeatingPlan(state.event)) {
    return isRegistrationClosedVisible(state.event) || !hasTicketsOnSale(state.tickets)
  }
  return (
    isRegistrationClosedVisible(state.event) || !getSelectedTicketsQuantity(state) || !areSelectedDonationsValid(state)
  )
}

export const isRestrictedTo = (state: State, visitorType: VisitorType) =>
  state.membersAreaEnabled && getRestrictedTo(state.event) === visitorType
